@import '../../styles/global.scss';

.Container {
    max-width: 100vw;
    display:flex;
    flex-flow: column;
    justify-content:space-around;
    align-items: center;

    padding: 20px;
}

.Contents {
    max-width: 100%;

    h1 {
        border-bottom: 3px ridge $dark-color-50;
        margin-bottom: 2rem;
        font-size: 200%;
    }
    h2 {
        border-bottom: 1px solid $dark-color-50;
        width: 40rem;
        max-width: 100%;
        font-size: 160%;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }
    h3 {
        font-size: 130%;
        margin-top: 1rem;
        margin-left: 1rem;
        border-left: 5px solid $dark-color-50;
        padding-left:5px;
        padding-top: 3px;
    }
    h3 + ol {
        margin-left: 1rem;
    }
    p, ul {
        // margin-left: 2rem;
        line-height: 2rem;
    }

    a {
        color: $secondary-color;

    }

    img {
        max-width: 100%;
    }
    .Back {
        margin-top: 5rem;
        font-size: 130%;
        text-align: center;
    }
}