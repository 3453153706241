// $primary-color: #B6967A;
// $secondary-color: #666;

$primary-color: #f8bc72;
$secondary-color: #A6866A;
$info-color:#CED979;


$dark-color-80: #555;
$dark-color-50: #aaa;
$dark-color-25: #eee;
