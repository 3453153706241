@import './styles/global.scss';

.Message {
    position: absolute;
    top: calc(50vh - 1rem);
    width: 100vw;
    text-align: center;
    font-size: 150%;
    color: $dark-color-50;
}
